<template>
    <div v-bind:style="styles">
      <div  v-if="!viLoader">
       
        <div class="row">
          <div class="col-lg-12">
            <div class="widjet">
              <div class="widjethdr dispflex">
                <div style="width: auto">
                  <span class="iconsect blubg" style="padding-left: 9px;"
                    ><i class="fas fa-users"></i
                  ></span>
                  <h1>Visitor Tracking</h1>
                </div>
                <!-- <button
            type="button"
            style="width:auto;height:36px;"
            class="btn btnsml"
            id="svbtn"
            @click="generateSectionOpen"
          >
            Generate Certificate
          </button> -->
                <div class="dispflex" >
                <div style="width: 200px" class="flexitm">
                  <!-- <span class="has-float-label">
                    <select
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="searchCertificateType"
                    > 
                      <option value=''>--Select--</option>
                      <option
                        v-for="(item, index) in filterCertificateTypes"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                    <label for="acyearid"
                      >Sort By Certificate Type <span class="required">*</span></label
                    >
                  </span> -->
                  
                </div>
                <div style="width: 97px" class="flexitm">

                  <button style="position: absolute;right: 14px;" class="btn gry_bg tooltipt mpopup twoicn"  @click.prevent="openActionPopUp()">
                  <i class="fas fa-receipt user1"></i>
                        <p class="user2"><i class="fas fa-plus"></i></p>
                        <span class="tooltiptext">Create</span>
                </button>

                    <!-- <button
            type="button"
            style="width:auto;height:36px;"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="openActionPopUp()"
          >
            Create
          </button> -->
                  <!-- <span class="has-float-label">
                    <select
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="searchWords"
                    >
                      <option value=''>--Select--</option>
                      <option
                        v-for="(item, index) in statusList"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                    <label for="acyearid"
                      >Sort By Status <span class="required">*</span></label
                    >
                  </span> -->
                  
                </div>
              </div>
             
            </div>
            <div class="widjetcontent">
                <div class="restable">
                  <div class="resrow resheader">
                    <div class="cell">Reason</div>
                    <div class="cell">Relation</div>
                    <div class="cell">Description</div>
                    <div class="cell">Created Date</div>
                    <div class="cell">Created By</div>
                    <div class="cell">Status</div>
  
                    <div class="cell" >Action</div>
                  </div>
                  <div
                    class="resrow"
                    v-for="(data, index) in visitorTrackingList"
                    :key="index"
                  >
                    <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                    <div class="cell" data-title="Candidate Name" >{{  data.reason }}</div>
                    <div class="cell" data-title="Candidate Name" >{{  data.relation }}</div>

                    <div class="cell" data-title="Candidate Name" >{{  data.description }}</div>

                    <div  class="cell" data-title="Role">
                            {{ data.updatedDate | dateFormat }}
                          </div>
                    <div class="cell" data-title="Department Name">
                        {{ data.updatedBy ? data.updatedBy.firstName + " " +  data.updatedBy.lastName : '' }}
                      </div>
                    

                    <div class="cell" data-title="Candidate Name" :style="data.status == 'Completed' ? 'color: lawngreen;' : 'color: #ffb100;' ">{{  data.status  }}</div>

  
                    <div class="cell" data-title="Action">
                    <!-- <b-dropdown v-if="data.status != 'Active'"
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                      <b-dropdown-item
                       @click.prevent="updateVisitor(data)"
                       
                        >Change Status to "Active"</b-dropdown-item
                      >
                     
                    </b-dropdown> -->
                    <button    
                                    class="btn round red tooltipt"
                                    :disabled="data.status == 'Completed'"
                                    @click.prevent="confirmDelete(data)"
                                  >
                                    <i class="far fa-trash-alt"></i>
                                    <span class="tooltiptext">Delete</span>
                                  </button>
                  
                  </div>
                  </div>
                </div>
              </div>
                          <div class="widjetfooter text-right dk_iconsml" >
  
          
          </div>
        </div>
      </div>
      
    </div>
     <div>
    
    </div>
      <vi-spinner
        v-if="viLoader"
        text="Loading..."
        textColor="vi-brand-color"
        class="flex-fill h-100 vi-fs12"
        style="
          width: 100%;
          z-index: 111;
          top: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 1rem;
        "
      />
    </div>
    <div>
      <b-modal
        id="createPopUp"
        class="modal"
        no-close-on-backdrop
        no-close-on-esc
      >        
        <button type="button" class="close"  @click.prevent="closeVisitor">×</button>

        <div class="contact-form">
          <h2>Create New Gate Pass</h2>
          <br />

          <div>

            <form
              data-vv-scope="admissionValidate"
              id="crtadmission"
              accept-charset="utf-8"
            >
              <div class="form-row">
                
                <!-- <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label" >
                    <select
                    v-validate="'required'"
                    class="form-control form-input"
                    id="deptlistid"
                    name="classvisitor"
                    v-model="visitorTracking.class"
                    @change="getSection(visitorTracking.class)"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in classList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.className }}
                    </option>
                  </select>
                  <label
                            v-if="errors.first('admissionValidate.classvisitor')"
                            class="vi-error"
                            style="top:-1em"
                            >Class is required</label
                          >
                    <label for="deptid" v-else
                      >Select Class <span class="required">*</span></label
                    >
                  </span>
                </div> -->
                <!-- <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label" >
                    <select
                    v-validate="'required'"
                    @change="getStudentList"
                    class="form-control form-input"
                    id="deptlistid"
                    name="sectionvisitor"
                    v-model="visitorTracking.classroom"
                  >
                    <option :value="null">-- Select --</option>

                    <option
                      v-for="(item, index) in sectionList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <label
                            v-if="errors.first('admissionValidate.sectionvisitor')"
                            class="vi-error"
                            style="top:-1em"
                            >Classroom is required</label
                          >
                    <label for="deptid" v-else
                      >Select Classroom <span class="required">*</span></label
                    >
                  </span>
                </div> -->
                <!-- <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label" >
                    <select
                          v-validate="'required'"
                          name="student"
                          v-model="visitorTracking.student"
                          class="form-control form-input"
                          id="sblgname"
                         
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in studentList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.firstName + " " + item.lastName }}  
                          </option>
                        </select>
                        <label
                            v-if="errors.first('admissionValidate.student')"
                            class="vi-error"
                            style="top:-1em"
                            >Student is required</label
                          >
                    <label for="deptid" v-else
                      >Select Student Name <span class="required">*</span></label
                    >
                  </span>
                </div> -->
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="number"
                      name="phoneNumber"
                      v-validate="'required'"
                      class="form-control form-input"
                      id="stdDob"
                    />
                    <label
                            v-if="errors.first('admissionValidate.phoneNumber')"
                            class="vi-error"
                            style="top:-1em"
                            >Phone No is required</label
                          >
                    <label for="deptid" v-else
                      >Phone No <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <select
                          v-validate="'required'"
                          name="reason"
                          v-model="visitorTracking.reason"
                          class="form-control form-input"
                          id="sblgname"
                         
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in reasonList"
                            :key="index"
                            :value="item"
                          >
                            {{ item }}  
                          </option>
                        </select>
                        <label
                            v-if="errors.first('admissionValidate.reason')"
                            class="vi-error"
                            style="top:-1em"
                            >Reason is required</label
                          >
                    <label for="deptid" v-else
                      >Reason <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <select
                          v-validate="'required'"
                          name="relation"
                          v-model="visitorTracking.relation"
                          class="form-control form-input"
                          id="sblgname"
                         
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in relationList"
                            :key="index"
                            :value="item"
                          >
                            {{ item }}  
                          </option>
                        </select>
                        <label
                            v-if="errors.first('admissionValidate.relation')"
                            class="vi-error"
                            style="top:-1em"
                            >Relation is required</label
                          >
                    <label for="deptid" v-else
                      >Relation <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <textarea
                           
                            class="form-control web-form-input"
                            style="min-height:135px;resize:none"
                            id="message"
                            
                            v-model="visitorTracking.description"
                          ></textarea>
                  
                    <label for="deptid"
                      >Description </label
                    >
                  </span>
                </div>
                
              </div>
              <div class="widjetfooter">
                <!-- <div class="dk_icon"></div> -->
                <div class="text-center dk_iconsml">
                  <button type="button"  class="btn btnsml" id="svbtn" @click="saveVisitor">
                      <span>Create</span>
                  </button>
                  <button type="button" class="btn btncl clsmdl" id="clbtn" @click.prevent="closeVisitor">
                       <span>Close</span>
                  </button>
                </div>
              </div>
              
            </form>
          </div>
         
        </div>
      </b-modal>
    </div>
    </div>
  </template>
  <script>
  import ViSpinner from "../Common/ViSpinner";
  import ViService from "@/services/ViService";
  import secureUI from "../../utils/secureUI";
  import Select from "vue-select";
  import "vue-select/dist/vue-select.css";
  
  export default {
    name: "candidates",
    data() {
      return {
       
        viLoader: false,
        visitorTrackingList: [],
        studentDetails: {},
        classList: [],
        studentList: [],
        sectionList: [],
        visitorTracking: {
          class: null,
          classroom: null,
          student: null,
          reason: null,
          relation: null,
          status: 'Submitted',
          description: '',
          phone: null,
          accountId: null,
          branchId: null,
          academicYear: null
        },
        reasonList: [
          'To take back home',
          'Parents Teacher Meeting',
          'Others'
        ],
        relationList: [
          'Father',
          'Mother',
          'Brother',
          'Other'
        ],
        
  
       
      };
    },
    created() {
      this.initCompent()
     
    },
    computed: {
      styles() {
        var brwHeight = window.innerHeight;
        return {
          height: brwHeight - 90 + "px",
        };
      },
     
    },
    watch: {
     
    
    },
    methods: {
      async initCompent(){
      await this.getStudentDetails();
      await this.getVisitorList()
      },
    
    async saveVisitor() {

      let isFormValid = false;

      await this.$validator.validateAll('admissionValidate').then(result => {
      isFormValid = result;
      });

      if(isFormValid) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
      this.$toasted.error("Please login and do the action");
      this.$router.push('/login');
      } else {
        this.visitorTracking.accountId = this.studentDetails.account._id,
        this.visitorTracking.student = this.$route.params.id,
        this.visitorTracking.branchId = this.studentDetails.branchId,
        this.visitorTracking.academicYear = this.studentDetails.academicYear._id
        this.visitorTracking.class = this.studentDetails.class._id

        this.visitorTracking.classroom = this.studentDetails.section._id

        // accountId: null,
        //   branchId: null,
        //   academicYear: null
      //this.viLoader = true;
      const response = await ViService.viXPost("/home/studentSaveVisitor", this.visitorTracking,userData.token);

      if (response.isSuccess) {
      this.closeVisitor();
      this.getVisitorList()
      this.$toasted.success(response.message);
      } else {
      this.$toasted.error(response.message);
      // if(response.message == 'Your session has expired, please login'){
      //         localStorage.removeItem('user');
      //         this.$router.push('/login');
      // }
      }
      }
      }else{
      this.$toasted.error('Please fill all the required details');
      }
      },
    async getStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
  if(this.visitorTracking.class && this.visitorTracking.classroom){
        const response = await ViService.viXGet(`/contact/view/getStudentList?classId=${this.visitorTracking.class}&sectionId=${this.visitorTracking.classroom}`,userData.token);
        if (response.isSuccess) {
          this.studentList = secureUI.secureGet(response.data);
        //  this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }
    },
    async confirmDelete(data) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        //this.viLoader = true;
        const response = await ViService.viDelete(
          `/home/deleteVisitor/${data._id}`,
          userData.token
        );

        if (response.isSuccess) {
          this.getVisitorList()
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      this.viLoader = false;
    },
    async getStudentDetails() {
      let userData = secureUI.sessionGet("user");


        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          const response = await ViService.viXGet(`/contact/getStudentDetaiils/${this.$route.params.id}`,userData.token);
          
          if (response.isSuccess) {
            this.studentDetails = response.data || {}

          } else {
            this.$toasted.error(response.message);
          }
        }
   
    },
    async getVisitorList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
       // let userData = secureUI.sessionGet("user");

       const response = await ViService.viXGet(`/home/studentgetvisitor/${this.$route.params.id}`,userData.token);
        if (response.isSuccess) {
          this.visitorTrackingList = secureUI.secureGet(response.data);
       
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    openActionPopUp(){
      this.$bvModal.show("createPopUp");
    },
    closeVisitor(){
      this.$bvModal.hide("createPopUp");
      this.visitorTracking = {
          class: null,
          classroom: null,
          student: null,
          reason: null,
          relation: null,
          description: '',
          phone: null,
          status: 'Submitted'
        }
    }
    },
    components: {
      "v-select": Select,
      ViSpinner,
    },
  };
  </script>
  
  <style>
  @media screen {
    #printSection {
      
        display: none;
    }
  }
  
  @media print {
    body * {
      visibility:hidden;
    }
    #printSection, #printSection * {
      visibility:visible;
    }
    #printSection {
      position:absolute;
      left:0;
      top:0;
    }
    #no-print,
    #no-print * {
      display: none !important;
    }
  }
  </style>